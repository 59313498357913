import { requestPlayfab } from './playfab-proxy';
import type { LoginResponse } from './login';

export function registerWithEmail(email: string, password: string): Promise<LoginResponse> {
    // Set up the request data
    const requestData = {
        endpointUrl: "/Client/RegisterPlayFabUser",
        method: "POST",
        body: {
            TitleId: true,
            Email: email,
            Password: password,
            RequireBothUsernameAndEmail: false,
        },
    };

    // Make the API call
    return requestPlayfab(requestData)
        .then((response) => {
            // Handle the response data
            const data = response.data;
            return { EntityToken: data.data.EntityToken.EntityToken, TokenExpiration: data.data.EntityToken.TokenExpiration, code: data.code, PlayFabId: data.data.PlayFabId, SessionTicket: data.data.SessionTicket };
        })
        .catch(error => {
            // Handle any errors
            const code = error.response.status;
            return { code: code, EntityToken: '', TokenExpiration: '', PlayFabId: '', SessionTicket: '' };
        });
}

// I did verify that registering still works after implementing the teacher cookie stuff