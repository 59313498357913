import React, { createContext, useState } from 'react';
import { LoginResponse } from '../ApiLib/login';
import { OrganizationResponse } from '../ApiLib/user-organization';
import { getCookieJSON, setCookieJSON } from '../browserStorage/first_party_cookies_lib';

const LOGIN_COOKIE = 'userLoginData';

interface LoginContextType {
  isLoggedIn: boolean;
  login: (response: LoginResponse, orgResponse?: OrganizationResponse, rememberMe?: boolean) => void;
  logout: () => void;
  entityToken: string;
  tokenExpiration: string;
  playFabId: string;
  sessionTicket: string;
  Organization: string;
  School_Role: string;
}

const LoginContext = createContext<LoginContextType>({
  isLoggedIn: false,
  login: (response: LoginResponse, orgResponse?: OrganizationResponse, rememberMe: boolean = false) => { },
  logout: () => { },
  entityToken: '',
  tokenExpiration: '',
  playFabId: '',
  sessionTicket: '',
  Organization: '',
  School_Role: ''
});


interface LoginProviderProps {
  children: React.ReactNode;
}

const LoginProvider: React.FC<LoginProviderProps> = ({ children }) => {
  const useLogin = () => {
    const defaultLoginData = {
      isLoggedIn: false,
      EntityToken: '',
      tokenExpiration: '',
      PlayFabId: '',
      sessionTicket: '',
      Organization: '',
      School_Role: ''
    }

    const loginDataCookie = getCookieJSON(LOGIN_COOKIE) ?? defaultLoginData;
    const [isLoggedIn, setIsLoggedIn] = useState(loginDataCookie.isLoggedIn);
    const [entityToken, setEntityToken] = useState(loginDataCookie.EntityToken);
    const [tokenExpiration, setTokenExpiration] = useState(loginDataCookie.TokenExpiration);
    const [playFabId, setPlayFabId] = useState(loginDataCookie.PlayFabId);
    const [sessionTicket, setSessionTicket] = useState(loginDataCookie.SessionTicket);
    const [Organization, setOrganization] = useState<string>(loginDataCookie.Organization)
    const [School_Role, setSchoolRole] = useState<string>(loginDataCookie.School_Role)

    const login = (response: LoginResponse, orgResponse?: OrganizationResponse, rememberMe: boolean = false) => {
      setIsLoggedIn(true);
      setEntityToken(response.EntityToken);
      setTokenExpiration(response.TokenExpiration);
      setPlayFabId(response.PlayFabId);
      setSessionTicket(response.SessionTicket);
      let userOrg = "";
      try {
        if (orgResponse?.data.data.Data.ORGANIZATION.Value !== undefined){ // If the user doesn't have an ORGANIZATION variable on their account, this will fail, hence the try catch
          userOrg = orgResponse?.data.data.Data.ORGANIZATION.Value;
        }else{
          userOrg = "Public"; // Public should be the default ORGANIZATION & SCHOOL_ROLE for any acount that does not already have an org or role
        }
      } catch (error) {
        if (orgResponse?.data.data.Data.ORGANIZATION !== undefined){
          userOrg = orgResponse?.data.data.Data.ORGANIZATION.Value;
        }else{
          userOrg = "Public";
        }
      }
      setOrganization(userOrg);
      let userRole= "";
      try {
        if (orgResponse?.data.data.Data.SCHOOL_ROLE.Value !== undefined){ // If the user doesn't have a SCHOOL_ROLE variable on their account, this will fail, hence the try catch
          userRole = orgResponse?.data.data.Data.SCHOOL_ROLE.Value;
        }else{
          userRole = "Public";
        }
      } catch (error) {
        if (orgResponse?.data.data.Data.SCHOOL_ROLE !== undefined){
          userRole = orgResponse?.data.data.Data.SCHOOL_ROLE.Value;
        }else{
          userRole = "Public";
        }
      }
      setSchoolRole(userRole);
      setCookieJSON({ name: LOGIN_COOKIE, value: { isLoggedIn: true, EntityToken: response.EntityToken, TokenExpiration: response.TokenExpiration, PlayFabId: response.PlayFabId, SessionTicket: response.SessionTicket, Organization: userOrg, School_Role: userRole }, days: (rememberMe ? 30 : null) });
    };

    const logout = () => {
      setIsLoggedIn(false);
      setEntityToken('');
      setTokenExpiration('');
      setPlayFabId('');
      setSessionTicket('');
      setOrganization('');
      setSchoolRole('');
      setCookieJSON({ name: LOGIN_COOKIE, value: defaultLoginData, days: null });
    };
     return { isLoggedIn, login, logout, entityToken, tokenExpiration, playFabId, sessionTicket, Organization, School_Role };
  };

  const loginContext = useLogin();

  return (
    <LoginContext.Provider value={loginContext}>
      {children}
    </LoginContext.Provider>
  );
};

export { LoginProvider, LoginContext };
