import { Flex, Spinner, Text } from "@chakra-ui/react";

export function LoadingBox({text}: {text?: string}) {
    const loadingText = text ? text : 'Loading...';
    return (
        <Flex
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="40px"
            background="blue.1"
            borderRadius="md"
            width='100%'
        >
            <Spinner size="lg" color="white" />
            <Text textColor='white' m='10px'>{loadingText}</Text>
        </Flex>
    )
}