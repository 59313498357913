import React, { useState, useEffect, useContext } from "react";
import {
    Flex,
    Box,
    Heading,
    Text,
    Image,
    List,
    ListItem,
} from '@chakra-ui/react';
import { ArrowRightIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import { LoginContext } from "../../stores/useLogin";
import { getOwnedCourses } from "../../ApiLib/course-ownership";
import { LoadingBox } from "../Components/LoadingBox";
import { FourOhThree } from "../Components/403";
import { FourOhFour } from "../Components/404";
import type { CourseMeta, CourseStore, Lab } from "../../CourseLib/course";
import { getCourseMetaByIds } from "../../CourseLib/course_meta";
import { getStoreInfoByIds } from "../../CourseLib/course_store";
import { LabView } from "./LabView";
import { useNavigate } from "react-router-dom";

export function CourseView() {
    const { courseId, labId } = useParams();
    const [ownedCourseIds, setOwnedCourseIds] = useState<string[]>([]);
    const { playFabId } = useContext(LoginContext);
    const [loading, setLoading] = useState<boolean>(true);
    const view = labId ? 'lab' : 'course';
    const labIdInt = parseInt(labId?.replace("lab", "") ?? "0");

    useEffect(() => {
        const fetchCourses = async () => {
            const ownedCourseIdsFromApi = await getOwnedCourses(playFabId);
            setOwnedCourseIds(ownedCourseIdsFromApi);
            setLoading(false);
        }
        fetchCourses();
    }, [playFabId, setOwnedCourseIds]);


    if (!courseId) {
        return (
            <FourOhFour />
        )
    }

    const course = {
        ...getCourseMetaByIds([courseId])[0],
        ...getStoreInfoByIds([courseId])[0]
    };

    if (labId && !course.labs[labIdInt - 1]) {
        return (
            <FourOhFour />
        )
    }

    if (loading) {
        return (
            <LoadingBox />
        );
    }

    if (!ownedCourseIds.includes(courseId)) {
        return (
            <FourOhThree />
        );
    }

    return (
        <Box p={{ base: 0, md: 4 }} width="100%">
            {view === 'course' && course.image && <Image src={course.image} alt={course.name} background={course.color} borderRadius={4} w="100%" h={40} objectFit={{ base: 'cover', md: 'contain' }} />}
            {view === 'course' && !course.image && <Flex background={course.color} borderRadius={4} w="100%" h={40} />}
            <Heading as="h1" size="xl" width="100%" lineHeight="1.5" mb={4} mt={-2} position='relative' z-index='5' background='blue.1' textColor='white' borderRadius={2} textAlign="center" paddingInline={2}>
                {course.name}
            </Heading>
            {view === 'lab' && <LabView lab={course.labs[labIdInt - 1]} />}
            {view === 'course' && <ViewCourse course={course} />}
        </Box>
    );
}

function ViewCourse({ course }: { course: CourseMeta & CourseStore }) {
    const [isHovered, setIsHovered] = useState(-1);
    const navigate = useNavigate();

    return (
        <>
            <Text mb={4}>{course.description}</Text>
            <List borderTop="1px" borderBottom="1px" borderColor="gray.200" onMouseLeave={() => setIsHovered(-1)}>
                {course.labs.map((lab: Lab, index: number) => (
                    <ListItem key={lab.name}>
                        <Flex onClick={() => navigate(`/course/${course.courseid}/lab${index + 1}`)} _hover={{ textDecoration: 'none', background: 'gray.100' }} onMouseEnter={() => setIsHovered(index)}>
                            <Heading as="h3" size="md" mr="auto" p={4}>
                                Lab {index + 1}: {lab.name}
                            </Heading>
                            {(isHovered === index) && <ArrowRightIcon alignSelf="center" mr={4} />}
                        </Flex>
                    </ListItem>
                ))}
            </List>
        </>
    );
}
