import { requestPlayfab } from "./playfab-proxy";

export type OrganizationResponse = { // This is the required data structure I discovered via Postman
    data:{
        code: number;
        data:{
            Data: {
                ORGANIZATION: {
                    Value: string;
                } 
                SCHOOL_ROLE: {
                    Value: string;
                } 
            }
            status: string
        }
    }
    
}

export async function getUserOrganization(playFabId: string): Promise<OrganizationResponse> {
    // Request User's data from the PlayFab server
    const requestData = {
        endpointUrl: "/Admin/GetUserData",
        method: "POST",
        body: {
            PlayFabId: playFabId,
            Keys: ["ORGANIZATION", "SCHOOL_ROLE"],
        },
    };

    return requestPlayfab(requestData);

}