import /* React, */ { useState, useEffect, useContext } from "react";
import { Text, Box, VStack, Table, Thead, Tr, Th, Td, Tbody, Flex, Button, Collapse } from "@chakra-ui/react";
import { CheckIfUserIsTeacherOr404 } from "../../../ApiLib/useful-functions";
// import { ArrowRightIcon } from "@chakra-ui/icons";
// import { useParams } from "react-router-dom";
import { LoginContext } from "../../../stores/useLogin";
// import { getOwnedCourses } from "../../../ApiLib/course-ownership";
// import { LoadingBox } from "../../Components/LoadingBox";
// import { FourOhThree } from "../../Components/403";
// import { FourOhFour } from "../../Components/404";
// import type { CourseMeta, CourseStore, Lab } from "../../../CourseLib/course";
// import { getCourseMetaByIds } from "../../../CourseLib/course_meta";
// import { getStoreInfoByIds } from "../../../CourseLib/course_store";

// import { getUserOrganization } from "../../../ApiLib/user-organization";
import { getLabData } from "../../../ApiLib/lab-data";
import { LoadingBox } from "../../Components/LoadingBox";
import chemistry_lab_data from '../../../vlab-courses/chemistry/labData.json';
import { getPlayerProfile } from "../../../ApiLib/getPlayerProfile";
import { getStudentList, Student } from "../../../ApiLib/getStudentList";

// const SageGreen = "#3e7800"; // Sage's official green, using color picker on the heading text on sagehillschool.org



async function GetStudentData(playFabId: string) {

    //Get the CLASS object of the teacher which is a list of student Play Fab Ids= ["abc", "def","etc."]
    const classIds = await getStudentList(playFabId);
    let studentNames = [];
    const studentPFids: string[] = JSON.parse(classIds.data.data.Data.CLASS.Value);
    
    const classSize = studentPFids.length;
    for(let i = 0; i < classSize; i++){
        const playerData = await getPlayerProfile(studentPFids[i]);
        const player: Student = {
            playFabId: studentPFids[i],
            name: playerData.data.data.PlayerProfile.DisplayName
        }
        studentNames.push(player);
    }
    return studentNames;
}

function LabCollapser({ buttonText, students, courseId, labId }: { buttonText: string, students: Student[], courseId: string, labId: string }) {
    const [show, setShow] = useState(false);
    const handleToggle = () => setShow(!show);

    return (
        <>
            <Button bg={"blue.600"} textColor={"white"} onClick={handleToggle}>
                {buttonText}
            </Button>
            <Box mt={4}>
                <Collapse in={show}>
                    {students.map((student, index) => (
                        <StudentCollapser
                            key={index}
                            student={student}
                            courseId={courseId}
                            labId={labId}
                            index={index}
                        />
                    ))}
                </Collapse>
            </Box>
        </>
    );
}

function StudentCollapser({ student, courseId, labId, index }: { student: Student, courseId: string, labId: string, index: number }) {
    const [show, setShow] = useState(false);
    const handleToggle = () => setShow(!show);

    return (
        <>
            <Button bg={"blue.400"} textColor={"white"} onClick={handleToggle}>
                {student.name}
            </Button>
            <Flex mt={4} bg="white" borderRadius={"10px"} margin={"10px"}>
                <Collapse in={show}>
                    <Flex padding={"10px"}>
                        <LabDataTable
                            key={index}
                            student={student}
                            courseId={courseId}
                            labId={labId}
                        />
                    </Flex>
                </Collapse>
            </Flex>
        </>
    );
}

export default LabCollapser;
export { StudentCollapser };

export function StudentAnswers() {
    /* 
    To do now:

    TO do soon:

    Be able to name and have multiples classes of students
    Link to student management from each class shown
     Collect and show student scores (hopefully in a cookie that saves the time 
        and date the info was last pulled from the vlab server with a button
        to pull and refresh the data)
    Chakra Collapse object per lab, with a grid inside to display each student and their scores/asnwers
        or maybe include the table already used in the labview page just to get it up[ and running ASAP
    */

    //BLOCKER TO DISALLOW NON TEACHERS FROM ACCESSING
    CheckIfUserIsTeacherOr404();
    const { playFabId } = useContext(LoginContext);

    const [students, setStudents] = useState<Student[]>([]);

    useEffect(() => {
        // Fetch and resolve the Promise
        const fetchStudents = async () => {
          const studentsData: Student[] = await GetStudentData(playFabId); // Assuming GetStudentData returns a Promise<Student[]>
          setStudents(studentsData);
        };
        fetchStudents();
    }, [playFabId]);
    
    return (
        <Flex bg="#3e7800" align="center" justify="center" w='auto' h='auto' margin='auto' padding='30px' borderRadius='5px'>
        <VStack spacing={2} w="full" maxW="md" alignContent='center'>
            <LabCollapser buttonText="Lab 3: Element Flame Test" students={students} courseId="2" labId="lab3" />
            <LabCollapser buttonText="Lab 4: Hydrate Experiment" students={students} courseId="2" labId="lab4" />
            <LabCollapser buttonText="Lab 5: Precipitation Experiment" students={students} courseId="2" labId="lab5" />
            <LabCollapser buttonText="Lab 6: Heat Capacity Experiment" students={students} courseId="2" labId="lab6" />
        </VStack>
    </Flex>

    );

}


// Create and display the user-entered data for a given lab.
function LabDataTable({ student, courseId, labId, }: { student: Student; courseId: string; labId: string}) {
   const playFabId = student.playFabId;
    const numericalLabId = labId?.substring(3, labId?.length); // Ex. "lab4" -> "4"
    const [isLoading, setIsLoading] = useState(true);



    // Set up interface for user data 
    // @Alex, I think we need to ask the VR crew to only upload the answers, and the units.
    // We don't need the questions stored on every users account
    interface UserInputData {
        [page: string]: {         // ex. = "p1"
            [question: string]: { // ex. = "q3"
                label: string;    // ex. = "What is the melting temperature of lead?"
                answer: string;   // ex. = "327"
                unit: string;     // ex. = "Degress Celsius"
            };
        };
    }



    type Question = {
        questionNumber: number;
        label: string;
        answer: string;
        unit: string;
    }

    type PageData = {
        pageNumber: number;
        pageTitle: string;
        questions: Array<Question>;
    }

    type LabData = {
        labid: string;
        dataPad: Array<PageData>;
    }

    type StaticLabData = {
        courseid: string;
        labs: Array<LabData>;
    }

    // Declare the static data from vlab-courses/chemistry/labData.json
    // @ts-ignore
    const staticData: StaticLabData = chemistry_lab_data as StaticLabData;


    // Get the user's lab data / answers from playfab. Then send the data to the table. Runs on page load.
    const [fetchedData, setFetchedData] = useState<UserInputData>({});
    useEffect(() => {
        // Get user data from PlayFab
        const getDataForLab = async () => {
            if (courseId !== undefined && numericalLabId !== undefined && labId !== undefined) {
                const getData = await getLabData(playFabId, courseId, numericalLabId.toString());
                setFetchedData(getData);
            } else {
                throw new Error("Either courseId or labId is undefined.");
            }
            setIsLoading(false);
        }
        getDataForLab();
    }, [courseId, labId, numericalLabId, playFabId]
    );

    if (isLoading) {
        return (
            <LoadingBox text={"Loading lab data..."} />
        );
    }

    //con.log(fetchedData);
    
    // Replace hardcoded question text below with question from appropriate vlab-courses data. Look up by courseid and labid.
    // You can grep for `vlab-courses` on the repo to see examples of how I did it for course meta data and store data.
    // If unit is null from vlab-courses object for given page / question => replace with users unit from above api call data.
    // Replace `Tablet Page #` with vlab courses pageTitle ("page-title": "Forms Precipitate?") if it exists for the given page

    if (staticData === undefined) {
        return null;
    }
    const staticLabData = staticData.labs.find(lab => lab.labid === numericalLabId);
    if (staticLabData === undefined) {
        return null;
    }

    type AugmentedLabData = PageData & { showUnits: boolean };

    var augmentedLabData: Array<AugmentedLabData> = [];
    if (numericalLabId !== undefined) {
        const labIndex = parseInt(numericalLabId) - 1;
        const pagesArray = Object.entries(fetchedData);
        pagesArray.forEach(([page, questions], pageIndex) => { //Playfab
            // Copy the static data as the base of the augmented lab data.
            if (!augmentedLabData[pageIndex]) {
                augmentedLabData[pageIndex] = {
                    pageNumber: staticLabData.dataPad[pageIndex].pageNumber,
                    pageTitle: staticLabData.dataPad[pageIndex].pageTitle,
                    questions: staticLabData.dataPad[pageIndex].questions,
                    showUnits: false,
                };
            }

            const questionsArray = pagesArray.length > 1 ? Object.entries(questions).slice(1) : Object.entries(questions);
            var pageHasUnits = false;
            questionsArray.forEach(([question, { answer, unit }], questionIndex) => {//Playfab
                if (labIndex >= 0 && labIndex < staticData.labs.length && staticLabData.dataPad.length > 0) {
                    augmentedLabData[pageIndex].questions[questionIndex] = {
                        questionNumber: staticLabData.dataPad[pageIndex].questions[questionIndex].questionNumber,
                        label: staticLabData.dataPad[pageIndex].questions[questionIndex].label,
                        answer: answer || "not submitted",
                        unit: unit !== "" ? unit : getDisplayUnit(staticLabData.dataPad[pageIndex].questions[questionIndex].unit),
                    };
                    if (augmentedLabData[pageIndex].questions[questionIndex].unit !== "") {
                        pageHasUnits = true;
                    }
                } else {
                    throw new Error("Invalid lab index or data structure for updating questions.");
                }
            });
            augmentedLabData[pageIndex].showUnits = pageHasUnits;
        });
    }

    if (Object.keys(fetchedData).length > 0) {
        return (
            <VStack align='left' spacing={2} w='100%' paddingTop={2}>
                {augmentedLabData.map((page: AugmentedLabData, index) => {
                    return (
                        <Box overflowX={'auto'} paddingTop={2}>
                            {/* <TableContainer key={index}> */}
                            <Table variant="simple" colorScheme="blue.1">
                                <Thead>
                                    <Text as="b" p="4px 0" color="#3e7800">{`Tablet Page ${page.pageNumber}: ${page.pageTitle}`}</Text> {/* Students name as the table header */}
                                    <Tr>
                                        <Th color="blue.2">Question</Th>
                                        <Th color="blue.2">Answer</Th>
                                        {page.showUnits && <Th color="blue.2">Unit</Th>}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {page.questions.map((question: Question, index: number) => {
                                        const answerStyle = getTableTextStyle(question.answer);
                                        const unitStyle = getTableTextStyle(question.unit);
                                        return (
                                            <Tr key={index}>
                                                <Td>{question.label}</Td>
                                                <Td {...answerStyle}>{question.answer}</Td>
                                                {page.showUnits && <Td {...unitStyle}>{question.unit}</Td>}
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                            {/* </TableContainer> */}
                        </Box>
                    );
                }
                )}
            </VStack>
        );
    }

    return (
        <div style={{ backgroundColor: '#B2F5EA', display: 'inline-block', minWidth: 'auto', minHeight: 'auto', borderRadius: '10px', padding: '10px' }}>
            <h1><b>{student.name} has not submitted answers. Please ask them to hit the export data button on the data pad for Lab {numericalLabId} in the VLab simulation.</b></h1>
        </div>
    )
}

function getDisplayUnit(unit: string): string {
    switch (unit) {
        case "USER_INPUT":
        case "DROP_DOWN":
            return "not submitted";
        default:
            return unit;
    }
}

function getTableTextStyle(value: string): object {
    if (value === "not submitted") {
        return {
            color: "gray.500",
            fontStyle: "italic"
        };
    }

    return {
        color: "gray.800",
        fontStyle: "normal"
    };
}