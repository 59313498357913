import React, { useContext } from 'react';
import { Box, Text, Button, Flex, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { LoginContext } from '../../stores/useLogin';
import { PrivatePageWrapper } from './PrivatePageWrapper';
import { PublicPageWrapper } from './PublicPageWrapper';
import brainLogo from '../images/brainlogo.png';


export function FourOhFour() {
    return (
        <Box
            height="100%"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
        >
            <Flex
                flexDirection="row"
            >
                <Image src={brainLogo} alt="vLab Logo" w='100px' margin='0 20px' />
                <Text fontSize="6xl" fontWeight="bold" mb="4">404</Text>
            </Flex>
            <Text fontSize="2xl" mb="4">
                Oops, you're lost in the virtual abyss!
            </Text>
            <Text fontSize="lg" mb="8">
                It seems like the VR portal you're searching for doesn't exist in this reality.
            </Text>
            <Button colorScheme="teal" as={Link} to="/dashboard" size="lg">
                Return to Home
            </Button>
        </Box>
    );
};

export function NotFound() {
    const { isLoggedIn } = useContext(LoginContext);

    if (isLoggedIn) {
        return (
            <PrivatePageWrapper headerTitle={'404'}>
                <FourOhFour />
            </PrivatePageWrapper>
        )
    }
    
    return (
        <PublicPageWrapper headerTitle={'404'}>
            <FourOhFour />
        </PublicPageWrapper>
    )
}
