import React, { useContext, useEffect, useState } from "react";
import { Text, SimpleGrid, Flex, Link } from "@chakra-ui/react";
import { CourseTileCard } from "../Components/CourseTile";
import type { CourseMeta } from "../../CourseLib/course";
import { getCourseMetaByIds } from "../../CourseLib/course_meta";
import { getOwnedCourses } from "../../ApiLib/course-ownership";
import { LoginContext } from "../../stores/useLogin";
import { LoadingBox } from "../Components/LoadingBox";

export function MyCourses() {
    const [ownedCourseIds, setOwnedCourseIds] = useState<string[]>([]);
    const { playFabId } = useContext(LoginContext);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchCourses = async () => {
            const ownedCourseIdsFromApi = await getOwnedCourses(playFabId);
            setOwnedCourseIds(ownedCourseIdsFromApi);
            setLoading(false);
        }

        fetchCourses();
    }, [playFabId, setOwnedCourseIds]);

    const ownedCourses = getCourseMetaByIds(ownedCourseIds);

    if (loading) {
        return (
            <LoadingBox />
        )
    }

    if (ownedCourses.length === 0) {
        return (
            <Flex bgColor='blue.2' width='fit-content' height='fit-content' padding='15px' textColor='white' borderRadius='4px' flexDirection='column' alignItems='center' margin='auto' flexWrap='wrap'>
                <Text>No courses found. You probably don't own any courses yet.</Text>
                <br />
                <Text>Go to the <Link href="/store" textColor='blue.1' textDecoration='underline'>Store</Link> to browse and purchase a couse.</Text>
            </Flex>
        )
    }

    return (
        <SimpleGrid columns={[1, 2, 3, 4]} spacing={4}>
            {ownedCourses.map((course: CourseMeta) => (
                <CourseTileCard key={course.name} course={course} showPrice={false} addToCart={() => {}} />
            ))}
        </SimpleGrid>
    );
}
