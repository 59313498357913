import React, { MouseEventHandler, useContext } from "react";
import { Box, Text, Image, Flex, VStack, Button } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { CartItemContext } from "../../stores/useCartItems";
import type { CourseMeta } from "../../CourseLib/course";

function PriceComponent({ price }: { price: number }) {
    const formattedPrice = price === 0 ? "Free" : "$" + price;

    return (
        <Text as='b' color="gray.600" textAlign='left'>{formattedPrice}</Text>
    );
}

function TileMetaHeader({ course, showPrice, addToCart }: { course: CourseMeta; showPrice: boolean; addToCart: (courseId: string) => void; }) {
    const { name, color, price } = course;
    const { cartItems } = useContext(CartItemContext);
    const cartItemCourseids = cartItems.map((item) => item.courseid);
    const [clicked, setClicked] = React.useState(cartItemCourseids.includes(course.courseid));

    const handleClick = React.useCallback<MouseEventHandler>(
        (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (!clicked) {
                addToCart(course.courseid);
            }
            setClicked(true);
        }, [clicked, setClicked, addToCart, course.courseid]
    );

    return (
        <Flex flexWrap='wrap'>
            <VStack alignItems='left'>
                <Text fontWeight="bold" fontSize="lg" paddingRight='2' color={color}>
                    {name}
                </Text>
                {showPrice && (<PriceComponent price={price} />)}
            </VStack>
            {showPrice && (<Button colorScheme='blue' variant='outline' size='sm' ml='auto' onClick={handleClick}>
                {clicked ? <Text>Added!</Text> : <AddIcon />}
            </Button>)}
        </Flex>
    )
}


export function CourseTileCard({ course, showPrice, addToCart }: { course: CourseMeta; showPrice: boolean; addToCart: (courseId: string) => void; }) {
    const { courseid, name, description, image, color } = course;
    const navigate = useNavigate();

    const onClickPath = (showPrice ? '/store/' : '/course/') + courseid;

    return (
        <Box
            maxW="sm"
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            boxShadow="md"
            height='fit-content'
            cursor='pointer'
            _hover={{ boxShadow: 'lg' }}
            onClick={() => { navigate(onClickPath) }}
        >
            {image && <Image src={image} alt={name} width='100%' aspectRatio='1' objectFit='cover' />}
            {!image && <Box aspectRatio='1' w='100%' bg={color} />}
            <Box p="4">
                <TileMetaHeader course={course} showPrice={showPrice} addToCart={addToCart} />
                <Text mt="2" color="gray.600" noOfLines={3} minH='4.5em'>
                    {description}
                </Text>
            </Box>
        </Box>
    );
};