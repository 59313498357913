import React from 'react';
import ReactDOM from 'react-dom/client';
import './App/styles/css/index.css';
import App from './App/App';
import reportWebVitals from './App/reportWebVitals';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { colors } from './App/styles/theme';
import { LoginProvider } from './stores/useLogin';
import { CartItemsProvider } from './stores/useCartItems';

const theme = extendTheme({ colors });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <LoginProvider>
        <CartItemsProvider>
          <App />
        </CartItemsProvider>
      </LoginProvider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(coon.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
