import { ArrowBackIcon, DownloadIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { Text, Box, Link, Heading, VStack, OrderedList, ListItem, UnorderedList, Table, Thead, Tr, Th, Td, Tbody } from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import type { Lab } from "../../CourseLib/course";
import { useEffect, useContext, useState } from "react";
import { getLabData } from "../../ApiLib/lab-data";
import { LoginContext } from "../../stores/useLogin";
import chemistry_lab_data from '../../vlab-courses/chemistry/labData.json';
import { LoadingBox } from "../Components/LoadingBox";
import ss1 from "../images/ss1.png";
import ss2 from "../images/ss2.png";
import ss3 from "../images/ss3.png";
import ss4 from "../images/ss4.png";
import ss5 from "../images/ss5.png";
import ss6 from "../images/ss6.png";
import datapad from "../images/datapad.jpg";
import datatable from "../images/datatable.png";
// import React, { Component } from 'react'
import { Image, /*Flex*/ } from "@chakra-ui/react";
// import {Tfoot, TableCaption, TableContainer,} from '@chakra-ui/react'
import { Stack, Divider, /*ButtonGroup, Button,*/ Card, /*CardHeader,*/ CardBody, /*CardFooter*/ } from '@chakra-ui/react'

export function LabView({ lab }: { lab: Lab }) {
    const { courseId, labId } = useParams();
    const labIdInt = parseInt(labId?.replace("lab", "") ?? "0");
    const navigate = useNavigate();

    return (
        <VStack w='100%'>
            <Link onClick={() => navigate(`/course/${courseId}`)} color="blue.1" _hover={{ textDecoration: "none", backgroundColor: "blue.1", color: "white" }} alignSelf='start' fontSize='lg' fontWeight='bold' p={2} borderRadius={4}>
                <ArrowBackIcon marginTop="-2px" fontSize='xl' />
                Back to Course
            </Link>
            <VStack p={{ base: 0, md: 4 }} width='100%'>
                <Heading as="h3" size="md" mr="auto">
                    Lab {labIdInt}: {lab.name}
                </Heading>
                <Text>{lab.description}</Text>
                <LearningObjectives learningObjectives={lab.learningObjectives} />
                <SpecialInstructions specialInstructions={lab.specialInstructions} />
                <Procedure worksheetPath={lab.worksheetPath} sdsSheetPath={lab.hasSDS ? '/sds-sheets/' + lab.name.replace(' ', '-') + "-SDS.zip" : undefined} />
                <Screenshots screenshots={lab.screenshots} />
                <Text>{lab.caption}</Text>
                <GetDataTable dataTable={lab.dataTable} />
                <Video video={lab.video} />
            </VStack>
        </VStack>
    );
}

function LearningObjectives({ learningObjectives }: { learningObjectives: string[] | null }) {
    if (!learningObjectives?.length) {
        return null;
    }

    return (
        <VStack align='left' spacing={2} mt={4} w='100%'>
            <Text as='b'>In this lab you will...</Text>
            <OrderedList>
                {learningObjectives?.map((learningObjective: string, index: number) => {
                    return (
                        <ListItem key={index}>{learningObjective}</ListItem>
                    );
                })}
            </OrderedList>
        </VStack>
    );
}

function SpecialInstructions({ specialInstructions }: { specialInstructions?: string }) {
    if (!specialInstructions?.length) {
        return null;
    }

    return (
        <VStack align='left' spacing={2} mt={4} w='100%'>
            <Text as='b'>Special Instructions:</Text>
            <Text>{specialInstructions}</Text>
        </VStack>
    );
}

function Video ({video}: {video?: string | null}) {
    if (!video?.length) {
        return null;
    }
    return (
        <iframe width="800" height="450" src="https://www.youtube.com/embed/KwRkhW2Ksck" title="VLab VR Introduction Video"></iframe>
    )
}

function Procedure({ worksheetPath, sdsSheetPath }: { worksheetPath: string; sdsSheetPath: string | undefined }) {
    if (worksheetPath === "") {
        return null;
    }

    const worksheetName = worksheetPath.split("/").pop();
    const sdsSheetName = sdsSheetPath?.split("/")?.pop();

    return (
        <VStack align='left' spacing={2} mt={4} w='100%'>
            <Text as='b' textDecoration="underline">Start Here:</Text>
            <OrderedList marginLeft={4} spacing={2}>
                <ListItem>
                    <Text><b>Download Lab Worksheet: </b>
                        <Link background="blue.1" borderRadius={2} padding="3px 6px" _hover={{ textDecoration: "none", background: "orange.1" }} href={worksheetPath} download>
                            <DownloadIcon verticalAlign="text-top" /> {worksheetName}
                        </Link>
                    </Text>
                </ListItem>
                {sdsSheetPath && (
                    <ListItem>
                        <Text><b>Download Safety Data Sheets: </b>
                            <Link background="blue.1" borderRadius={2} padding="3px 6px" _hover={{ textDecoration: "none", background: "orange.1" }} href={sdsSheetPath} download>
                                <DownloadIcon verticalAlign="text-top" /> {sdsSheetName}
                            </Link>
                        </Text>
                        <Text>
                            <WarningTwoIcon verticalAlign="text-top" />
                            This is not needed for completing this lab, but learning about safety is important.
                        </Text>
                    </ListItem>
                )}
                <ListItem>
                    <Text><b>Prelab Questions:</b> Answer the prelab questions from the Lab Worksheet PDF before starting.</Text>
                </ListItem>
                <ListItem>
                    <Box><b>Lab Procedure:</b>
                        <UnorderedList>
                            <ListItem>Follow the instructions in the Lab Worksheet PDF and complete any questions along the way.</ListItem>
                            <ListItem>As you complete the lab, fill out any relevant data in the virtual tablet.</ListItem>
                            <ListItem>At the end of the lab, export your data from inside the Vlab to see it populate here. Or, use a pencil and paper to write your answers down.</ListItem>
                        </UnorderedList>
                    </Box>
                    <LabDataTable />
                </ListItem>
                <ListItem>
                    <Text><b>Postlab Questions:</b> Answer the postlab questions from the Lab Worksheet PDF after completing the lab.</Text>
                </ListItem>
            </OrderedList>
        </VStack>
    );
}

// Create and display the user-entered data for a given lab.
function LabDataTable() {
    // Get playfab ID
    const { playFabId } = useContext(LoginContext);
    const { courseId, labId } = useParams();
    const numericalLabId = labId?.substring(3, labId?.length); // Ex. "lab4" -> "4"
    const [isLoading, setIsLoading] = useState(true);



    // Set up interface for user data 
    // @Alex, I think we need to ask the VR crew to only upload the answers, and the units.
    // We don't need the questions stored on every users account
    interface UserInputData {
        [page: string]: {         // ex. = "p1"
            [question: string]: { // ex. = "q3"
                label: string;    // ex. = "What is the melting temperature of lead?"
                answer: string;   // ex. = "327"
                unit: string;     // ex. = "Degress Celsius"
            };
        };
    }



    type Question = {
        questionNumber: number;
        label: string;
        answer: string;
        unit: string;
    }

    type PageData = {
        pageNumber: number;
        pageTitle: string;
        questions: Array<Question>;
    }

    type LabData = {
        labid: string;
        dataPad: Array<PageData>;
    }

    type StaticLabData = {
        courseid: string;
        labs: Array<LabData>;
    }

    // Declare the static data from vlab-courses/chemistry/labData.json
    // @ts-ignore
    const staticData: StaticLabData = chemistry_lab_data as StaticLabData;


    // Get the user's lab data / answers from playfab. Then send the data to the table. Runs on page load.
    const [fetchedData, setFetchedData] = useState<UserInputData>({});
    useEffect(() => {
        // Get user data from PlayFab
        const getDataForLab = async () => {
            if (courseId !== undefined && numericalLabId !== undefined && labId !== undefined) {
                const getData = await getLabData(playFabId, courseId, numericalLabId.toString());
                setFetchedData(getData);
            } else {
                throw new Error("Either courseId or labId is undefined.");
            }
            setIsLoading(false);
        }
        getDataForLab();
    }, [courseId, labId, numericalLabId, playFabId]
    );

    if (isLoading) {
        return (
            <LoadingBox text={"Loading lab data..."} />
        );
    }

    // Replace hardcoded question text below with question from appropriate vlab-courses data. Look up by courseid and labid.
    // You can grep for `vlab-courses` on the repo to see examples of how I did it for course meta data and store data.
    // If unit is null from vlab-courses object for given page / question => replace with users unit from above api call data.
    // Replace `Tablet Page #` with vlab courses pageTitle ("page-title": "Forms Precipitate?") if it exists for the given page

    if (staticData === undefined) {
        return null;
    }
    const staticLabData = staticData.labs.find(lab => lab.labid === numericalLabId);
    if (staticLabData === undefined) {
        return null;
    }

    type AugmentedLabData = PageData & { showUnits: boolean };

    var augmentedLabData: Array<AugmentedLabData> = [];
    if (numericalLabId !== undefined) {
        const labIndex = parseInt(numericalLabId) - 1;
        const pagesArray = Object.entries(fetchedData);
        pagesArray.forEach(([page, questions], pageIndex) => { //Playfab
            // Copy the static data as the base of the augmented lab data.
            if (!augmentedLabData[pageIndex]) {
                augmentedLabData[pageIndex] = {
                    pageNumber: staticLabData.dataPad[pageIndex].pageNumber,
                    pageTitle: staticLabData.dataPad[pageIndex].pageTitle,
                    questions: staticLabData.dataPad[pageIndex].questions,
                    showUnits: false,
                };
            }

            const questionsArray = pagesArray.length > 1 ? Object.entries(questions).slice(1) : Object.entries(questions);
            var pageHasUnits = false;
            questionsArray.forEach(([question, { answer, unit }], questionIndex) => {//Playfab
                if (labIndex >= 0 && labIndex < staticData.labs.length && staticLabData.dataPad.length > 0) {
                    augmentedLabData[pageIndex].questions[questionIndex] = {
                        questionNumber: staticLabData.dataPad[pageIndex].questions[questionIndex].questionNumber,
                        label: staticLabData.dataPad[pageIndex].questions[questionIndex].label,
                        answer: answer || "not submitted",
                        unit: unit !== "" ? unit : getDisplayUnit(staticLabData.dataPad[pageIndex].questions[questionIndex].unit),
                    };
                    if (augmentedLabData[pageIndex].questions[questionIndex].unit !== "") {
                        pageHasUnits = true;
                    }
                } else {
                    throw new Error("Invalid lab index or data structure for updating questions.");
                }
            });
            augmentedLabData[pageIndex].showUnits = pageHasUnits;
        });
    }

    if (Object.keys(fetchedData).length > 0) {
        return (
            <VStack align='left' spacing={2} w='100%' paddingTop={2}>
                {augmentedLabData.map((page: AugmentedLabData, index) => {
                    return (
                        <Box overflowX={'auto'} paddingTop={2}>
                            {/* <TableContainer key={index}> */}
                            <Table variant="simple" colorScheme="blue.1">
                                <Thead>
                                    <Text as="b" p="4px 0" color="blue.1">{`Tablet Page ${page.pageNumber}: ${page.pageTitle}`}</Text>
                                    <Tr>
                                        <Th color="blue.2">Question</Th>
                                        <Th color="blue.2">Your Answer</Th>
                                        {page.showUnits && <Th color="blue.2">Unit</Th>}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {page.questions.map((question: Question, index: number) => {
                                        const answerStyle = getTableTextStyle(question.answer);
                                        const unitStyle = getTableTextStyle(question.unit);
                                        return (
                                            <Tr key={index}>
                                                <Td>{question.label}</Td>
                                                <Td {...answerStyle}>{question.answer}</Td>
                                                {page.showUnits && <Td {...unitStyle}>{question.unit}</Td>}
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                            {/* </TableContainer> */}
                        </Box>
                    );
                }
                )}
            </VStack>
        );
    }

    return (
        <div style={{ backgroundColor: '#B2F5EA', display: 'inline-block', minWidth: 'auto', minHeight: 'auto', borderRadius: '10px', padding: '10px' }}>
            <h1><b>To view your answers, please hit the export data button on the data pad for Lab {numericalLabId} in the VLab simulation.</b></h1>
        </div>
    )
}

function getDisplayUnit(unit: string): string {
    switch (unit) {
        case "USER_INPUT":
        case "DROP_DOWN":
            return "not submitted";
        default:
            return unit;
    }
}

function getTableTextStyle(value: string): object {
    if (value === "not submitted") {
        return {
            color: "gray.500",
            fontStyle: "italic"
        };
    }

    return {
        color: "gray.800",
        fontStyle: "normal"
    };
}

function GetDataTable ({dataTable}: {dataTable?: string | null}) {
    if (!dataTable?.length) {
        return null;
    }
    return (
        <LabDataTable /> 
    )
}


function Screenshots ({screenshots}: {screenshots?: string[] | null}) {
    if (!screenshots?.length) {
        return null;
    }
    return (
        <VStack>
            <Text as='b'>Let's Get Started!</Text>

                {screenshots.map((screenshot: string, index: number) => {
                    if (screenshot === "ss1"){
                        return (
                            <Card maxW='xlg'>
                            <CardBody>
                                <Heading size='md'>Accessing the Dashboard</Heading>
                                <Image
                                src={ss1}
                                alt={'Accessing VLab\'s Dashboard with Getting Started Course'}
                                borderRadius='lg'
                                />
                                <Stack mt='6' spacing='3'>
                                <Text>
                                    Before you have access to purchased labs and courses, you will initially have access to our Getting Started course. Later on, your purchased courses and labs will appear on this page, labeled through the 'My Courses' tab. 
                                </Text>
                                </Stack>
                            </CardBody>
                            <Divider />
                            </Card>
                        );
                    }
                    if (screenshot === "ss2"){
                        return (
                            // <Image key={index} src={ss2} alt={`Screenshot ${index + 1}`} w='700px' margin='0 20px' />
                            <Card maxW='xlg'>
                            <CardBody>
                                <Heading size='md'>Getting Started Course</Heading>
                                <Image
                                src={ss2}
                                alt={'Getting Started Course Webpage'}
                                borderRadius='lg'
                                />
                                <Stack mt='6' spacing='3'>
                                <Text>
                                    Once you have clicked the Getting Started course, you will appear on this page, which have led you to this lab tutorial. This is the first introductory course for the website. As you can see, there are different labs to access, each containing their own lab descriptions, lab objectives, and lab special instructions. This is where you will learn how to navigate the website and learn how to start using our VR application.
                                </Text>
                                </Stack>
                            </CardBody>
                            <Divider />
                            </Card>
                        );
                    }
                    if (screenshot === "ss3"){
                        return (
                            // <Image key={index} src={ss3} alt={`Screenshot ${index + 1}`} w='700px' margin='0 20px' />
                            <Card maxW='xlg'>
                            <CardBody>
                                <Heading size='md'>My Courses Tab</Heading>
                                <Image
                                src={ss3}
                                alt={'My Courses Tab Webpage with Introduction to Chemistry Course'}
                                borderRadius='lg'
                                />
                                <Stack mt='6' spacing='3'>
                                <Text>
                                    After purchasing the "Introduction to Chemistry" course, you will have access to the course through the 'My Courses' tab, as shown in the picture above. 
                                </Text>
                                </Stack>
                            </CardBody>
                            <Divider />
                            </Card>
                        );
                    }
                    if (screenshot === "ss4"){
                        return (
                            // <Image key={index} src={ss4} alt={`Screenshot ${index + 1}`} w='700px' margin='0 20px' />
                            <Card maxW='xlg'>
                            <CardBody>
                                <Heading size='md'>Purchased Course Page</Heading>
                                <Image
                                src={ss4}
                                alt={'Main Courses Page - Introduction to Chemistry'}
                                borderRadius='lg'
                                />
                                <Stack mt='6' spacing='3'>
                                <Text>
                                    Once you have clicked on one of your purchased courses, you will be directed to a webpage, consisting of the labs included in the package. This is where you will have access to the lab's instructions, lab objectives, starting steps, collected data information, and other course materials to help you prepare before, during, and after going through the lab.
                                </Text>
                                </Stack>
                            </CardBody>
                            <Divider />
                            </Card>
                        );
                    }
                    if (screenshot === "ss5"){
                        return (
                            // <Image key={index} src={ss5} alt={`Screenshot ${index + 1}`} w='700px' margin='0 20px' />
                            <Card maxW='xlg'>
                            <CardBody>
                                <Heading size='md'>Lab</Heading>
                                <Image
                                src={ss5}
                                alt={'Lab Website Page'}
                                borderRadius='lg'
                                />
                                <Stack mt='6' spacing='3'>
                                <Text>
                                    This is an example of what a lab page looks like. It contains the lab's name, description, learning objectives, special instructions, and other important information to help you prepare for the lab. This is where you will find the lab worksheet, the prelab questions, the description of the lab procedure, and the postlab questions for your students. 
                                </Text>
                                </Stack>
                            </CardBody>
                            <Divider />
                            </Card>
                        );
                    }
                    if (screenshot === "ss6"){
                        return (
                            <Card maxW='xlg'>
                            <CardBody>
                                <Heading size='md'>Updating Account Password and Profile Information</Heading>
                                <Image
                                src={ss6}
                                alt={'Account Website Page'}
                                borderRadius='lg'
                                />
                                <Stack mt='6' spacing='3'>
                                <Text>
                                    There is an "Account" tab on the sidebar on the left-hand side of the website. This is where you can update your account password and profile information, such as your contact email. You also have the opportunity to delete your account, if you wish to do so.
                                </Text>
                                </Stack>
                            </CardBody>
                            <Divider />
                            </Card>
                    );}
                    if (screenshot === "datapad"){
                        return (
                            <Card maxW='lg'>
                            <CardBody>
                                <Heading size='md'>Exporting Data from the VR Application</Heading>
                                <Image width='500px' height='400px'
                                src={datapad}
                                alt={'Datapad Photo from Vlab\'s VR Application with Export Data Button circled in red'}
                                borderRadius='lg'
                                />
                                <Stack mt='6' spacing='3'>
                                <Text>
                                    This is a datapad from our VR application, where students will input their answers of the lab questions to be exported in our database. Please make sure that they click the "Export Data" button to ensure that their answers are saved and displayed on the website.
                                </Text>
                                </Stack>
                            </CardBody>
                            <Divider />
                            </Card>
                    );}
                    if (screenshot === "datatable"){
                        return (
                            <Card maxW='lg'>
                            <CardBody>
                                <Heading size='md'>Exporting Data from the VR Application</Heading>
                                <Image width='500px' height='100px'
                                src={datatable}
                                alt={'Example photo of a data table from data exported through VLab\'s VR Application.'}
                                borderRadius='lg'
                                />
                                <Stack mt='6' spacing='3'>
                                <Text>
                                    This is an example of what a data table will look like, when accessing students' answer submissions of a completed or in-progress lab. The data table will be displayed in this format, including the question, the student's answer, and the unit. From this data, you will be able to evaluate students' progress with the labs. You will also be able to see students' understandings of the lab objectives, based on their answers to the lab questions, arising any concerns if steps have been followed properly and effectively.
                                </Text>
                                </Stack>
                            </CardBody>
                            <Divider />
                            </Card>
                    );}
                    return (
                        <Card maxW='lg'>
                        <CardBody>
                            <Heading size='md'>Exporting Data from the VR Application</Heading>
                            <Stack mt='6' spacing='3'>
                            <Text>
                                Below is an example of what you will see online when no data has been exported from the VR application. If a student has clicked the "Export Data" button and this message still shows up, please contact our Support Team to resolve the issue.
                            </Text>
                            </Stack>
                        </CardBody>
                        <Divider />
                        </Card>)
                    // return (
                    //     <Image key={index} src="{screenshot}" alt={`Screenshot ${index + 1}`} w='700px' margin='0 20px' />
                    // );
                })}

        </VStack>
    );
}
