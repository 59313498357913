import { requestPlayfab } from "./playfab-proxy";

export async function getLabData(playFabId: string, courseId: string, labId: string): Promise<any> {
    // Request User's answers from the playfab server
    const labDataKey = "COURSE_" + courseId + "_LAB_" + labId + "_DATA";
    const requestData = {
        endpointUrl: "/Admin/GetUserData",
        method: "POST",
        body: {
            PlayFabId: playFabId,
            Keys: [labDataKey],
        },
    };

    // Make the API call
    const response = await requestPlayfab(requestData)
        .then((response) => {
            return response;
        }).catch(error => {
            return error;
        });

    if (response.status !== 200) {
        return new Error("API call failed");
    }

    if (response.data.data.Data !== undefined && response.data.data.Data[labDataKey] !== undefined && response.data.data.Data[labDataKey].Value !== undefined) {
        return JSON.parse(response.data.data.Data[labDataKey].Value);
    }

    return [];
}