import /* React, { useState, */ { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../stores/useLogin";

//because typescript string equality is HELL
export function removeCharacter(str: string, charToRemove: string): string {
    // Create a regular expression with the global flag to find all instances of charToRemove
    const regex = new RegExp(charToRemove, 'g');
    // Replace all instances with an empty string
    return str.replace(regex, '');
}

//Check if a user is a Teacher. If they aren't, direct them to 404 (use at top of teacher restricted page)
//Usage: CheckIfUserIsTeacherOr404();
export function CheckIfUserIsTeacherOr404() {
    const navigate = useNavigate();
    const {School_Role} = useContext(LoginContext);
    const role = removeCharacter(School_Role, '"');

    useEffect(() => { if(role !== "Teacher") { navigate('*'); } }, [role,navigate]);
}