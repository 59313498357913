import React, { useState, useContext, useEffect } from "react";
import { Heading, Image, VStack, Flex, Spacer, Button, Box, useBreakpointValue, Accordion, AccordionItem, AccordionButton, AccordionPanel, Text } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, SettingsIcon, CalendarIcon, ArrowLeftIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import vLabLogo from "../images/VLabLogoCropped.png";
import SageLogo from "../images/SchoolLogos/SageHill.png";
import { LoginContext } from '../../stores/useLogin';
import { useNavigate } from "react-router-dom";
import { BaseFooter } from "./SageFooter";
import { removeCharacter } from "../../ApiLib/useful-functions";
// import { scheduler } from "timers/promises";

function BaseHeader({ headerTitle }: { headerTitle?: string }) {
    const SchoolHeaderArray = window.location.pathname.split('/'); // These 4 lines determine the words that will show up in the top right, based on the path. 
    let SchoolHeader = "";                                         // ie  path:"/Teahcer/Dashboard" = "Teacher Dashboard"
    for(let i = 1; i < SchoolHeaderArray.length; i++){ 
        SchoolHeader = SchoolHeader + " " +SchoolHeaderArray[i].toUpperCase();
    }

    const openTabHeader = headerTitle || SchoolHeader;
    const isMobile = useBreakpointValue({ base: true, sm: false });
    const SageGreen = "#3e7800"; // Sage's official green, using color picker on the heading text on sagehillschool.org

    return (
        <VStack>
            <Accordion allowMultiple width='100%'>
                <AccordionItem>
                    {({ isExpanded }) => (
                        <>
                            <Flex
                                bg="white" 
                                w='100%'
                                h='60px'
                                alignItems="center"
                            >
                                <Box>
                                <Image src={vLabLogo} alt="vLab Logo" w='100px' margin='0 20px' />
                                </Box>
                                <Box bg={SageGreen} h='60px' alignItems="center"> 
                                <Image src={SageLogo} alt="Sage Hill Logo" w='160px' margin='9px 10px 10px 10px' />
                                </Box>
                                <Spacer />
                                <Heading size="lg" padding='10px'>{openTabHeader}</Heading>
                                {isMobile && (
                                    <>
                                        <h2>
                                            <AccordionButton>
                                                {isExpanded ? (
                                                    <CloseIcon />
                                                ) : (
                                                    <HamburgerIcon />
                                                )}
                                            </AccordionButton>
                                        </h2>
                                    </>
                                )}
                            </Flex>
                            {isMobile && (
                                <AccordionPanel bgColor='gray.200' padding='0'>
                                    <NavBarItems />
                                </AccordionPanel>
                            )}
                        </>
                    )}
                </AccordionItem>
            </Accordion>
        </VStack>
    );
}

function NavBarItems() {
    const navigate = useNavigate();
    const { logout, School_Role } = useContext(LoginContext);
    const School_Role_String = removeCharacter(School_Role, '"');


    const activeTab = (() => {
        const path = window.location.pathname;
        if (path.includes('account')) {
            return 'Account';
        }
        if (path.includes('my-courses')) {
            return 'My Courses';
        }
        // if (path.includes('store')) {
        //     return 'Store';
        // }
        if (path.includes('Teacher/Dashboard')){
            return 'Teacher Dashboard';
        }
        return 'No active tab found';
    })();

    return (
        <VStack spacing='0px' align="center"> {/* boxSize is set on each icon to make them all visually the same size */}
            <NavBarItem text='Account' handleClick={() => navigate('/account')} icon={<SettingsIcon margin='auto' boxSize="20px"  />} isActive={activeTab === 'Account'} />
            <NavBarItem text='My Courses' handleClick={() => navigate('/dashboard/my-courses')} icon={<CalendarIcon margin='auto' boxSize="20px"  />} isActive={activeTab === 'My Courses'} />
            {/* <NavBarItem text='Store' handleClick={() => navigate('/store')} icon={<PlusSquareIcon margin='auto' />} isActive={activeTab === 'Store'} /> */}
            {School_Role_String === "Teacher" && ( //If the user is a Teacher, show the button to get back to the teacher dashboard
            <NavBarItem text={'Teacher Dashboard'}  handleClick={() => navigate('/Teacher/Dashboard')} icon={<ExternalLinkIcon margin='auto' boxSize="26px" style={{ transform: 'rotateY(180deg)' }} />} isActive={activeTab === 'Teacher Dashboard'} />
            )}
            <NavBarItem text='Log Out' handleClick={() => {
                logout();
                navigate('/login');
            }}
                icon={<ArrowLeftIcon margin='auto' boxSize="20px"  />}
            />
        </VStack>
    );
}

function NavBarItem({ text, handleClick, icon, isActive }: { text: string, handleClick: () => void, icon: React.ReactNode, isActive?: boolean }) {
    const isMobile = useBreakpointValue({ base: true, md: false });

    if (isMobile) {
        icon = null;
    }

    const textLines = text.length > 10 ? text.split(' ') : [text]; // Split into two lines if it is two words with more than 10 characters total. FYI: "My Courses".legnth = 10
    return (
        <Flex
            flexDirection='column'
            cursor='pointer'
            _hover={isActive ? { color: 'blue.2' } : { color: 'blue.1' }}
            bgColor={isActive ? 'blue.1' : 'white'}
            onClick={handleClick}
            width='100%'
            paddingTop='20px'
            paddingBottom='20px'
        >
            {icon}

            {/* Map each line to a Text component */}
            {textLines.map((line, index) => (
                <Text
                    key={index}
                    // fontSize={(text.length > 10 && 13) || 15}   Was trying to chnage font size to fit Teacher Dashboard better on the button, but it works fine if it's on two lines
                    textAlign="center" // Center text horizontally
                >
                    {line}
                </Text>
            ))}
        </Flex>
    );
}

function VerticalNavBar() {
    return (
        <Box
            position="sticky"
            top="0"
            w="120px"
            bg="white"
        >
            <NavBarItems />
        </Box>
    );
}

function SessionExpiredModal({ onClose }: { onClose: () => void }) {
    return (
        <Flex
            position="fixed"
            top="0"
            left="0"
            w="100%"
            h="100%"
            bg="rgba(0,0,0,0.6)"
            zIndex="1000"
            alignItems="center"
            justifyContent="center"
        >
            <Flex
                w="100%"
                maxW="sm"
                bg="white"
                p="20px"
                borderRadius="5px"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >
                <Heading size="lg" textAlign="center" mb="20px">
                    Your session has expired
                </Heading>
                <Button colorScheme="blue" onClick={onClose}>
                    Log in again
                </Button>
            </Flex>
        </Flex>
    );
}

export function SageHillWrapper(props: any) {
    const { isLoggedIn, login, tokenExpiration, logout } = useContext(LoginContext);
    const navigate = useNavigate();
    const isMobile = useBreakpointValue({ base: true, sm: false });
    const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login');
        }
        else if (new Date() >= new Date(tokenExpiration) || !tokenExpiration) {
            setShowSessionExpiredModal(true);
        }
    }, [isLoggedIn, navigate, login, tokenExpiration]);

    return (
        <Flex flexDirection='column' minH='100vh' bg='white'>
            <BaseHeader headerTitle={props.headerTitle} />
            {showSessionExpiredModal && (<SessionExpiredModal
                onClose={() => {
                    logout();
                    navigate('/login');
                }}
            />)}
            <Flex flex='1'>
                {!isMobile && <VerticalNavBar />}
                <Flex flex="1" p="20px">
                    {props.children}
                </Flex>
            </Flex>
            <BaseFooter />
        </Flex>
    );
}