import axios from 'axios';

type RequestData = {
    endpointUrl: string;
    method: string;
    customHeaders?: {
        [key: string]: string | undefined;
    };
    body?: any;
}

export function requestPlayfab(requestData: RequestData): Promise<any> {
    return axios.post(process.env.REACT_APP_SERVER_URL + '/playfab-proxy', requestData);
}
