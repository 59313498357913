import { requestPlayfab } from "./playfab-proxy";
import { getPlayerProfile } from "./getPlayerProfile";

export type ClassResponse = { // This is the required data structure I discovered via Postman
    data:{
        code: number;
        data:{
            Data: {
                CLASS: {
                    Value: string;
                } 
            }
        }
        status: string
    }
}
  
export type Student = {
    playFabId: string;
    name: string;
}

export async function getStudentList(playFabId: string): Promise<ClassResponse> {
    // Request User's data from the PlayFab server
    const requestData = {
        endpointUrl: "/Admin/GetUserData",
        method: "POST",
        body: {
            PlayFabId: playFabId,
            Keys: ["CLASS"]
        },
    };

    return requestPlayfab(requestData);

}

export async function GetStudentData(playFabId: string) {
        
    
    //Get the CLASS object of the teacher which is a list of student Play Fab Ids= ["123", "456","etc."]
    const classIds = await getStudentList(playFabId);
    let studentNames = [];
    const studentPFids: string[] = JSON.parse(classIds.data.data.Data.CLASS.Value);
    
    const classSize = studentPFids.length;
    for(let i = 0; i < classSize; i++){
        const playerData = await getPlayerProfile(studentPFids[i]);
        const player: Student = {
            playFabId: studentPFids[i],
            name: playerData.data.data.PlayerProfile.DisplayName
        }
        studentNames.push(player);
    }
    return studentNames;
}