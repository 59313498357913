// POST https://titleId.playfabapi.com/Admin/GetUserAccountInfo
// POST https://titleId.playfabapi.com/Client/GetPlayerProfile

import { requestPlayfab } from "./playfab-proxy";

type AccountData = AccountInfo & PlayerProfile;

type AccountInfo = {
    playerID: string;
    PrivateInfo: {
        Email: string;
    }
}

type PlayerProfile = {
    contactEmail: string;
}

export const defaultAccountData: AccountData = {
    playerID: '',
    PrivateInfo: {
        Email: '',
    },
    contactEmail: '',
};

function getAccountInfo(playFabId: string): Promise<AccountInfo> {
    // Set up the request data
    const requestData = {
        endpointUrl: "/Admin/GetUserAccountInfo",
        method: "POST",
        body: {
            PlayFabId: playFabId,
        },
    };

    // Make the API call
    return requestPlayfab(requestData)
        .then((response) => {
            // Handle the response data
            return response.data.data.UserInfo;
        })
        .catch(error => {
            // Handle any errors
            return error;
        });
}

function getPlayerProfile(playFabId: string, sessionTicket: string): Promise<PlayerProfile> {
    // Set up the request data
    const requestData = {
        endpointUrl: "/Client/GetPlayerProfile",
        method: "POST",
        customHeaders: {
            'X-Authorization': sessionTicket,
        },
        body: {
            PlayFabId: playFabId,
            ProfileConstraints: {
                ShowContactEmailAddresses: true,
            },
        },
    };

    // Make the API call
    return requestPlayfab(requestData)
        .then((response) => {
            // Handle the response data
            const data = response.data.data.PlayerProfile;
            return { contactEmail: data.ContactEmailAddresses[0]?.EmailAddress };
        })
        .catch(error => {
            // Handle any errors
            return error;
        });
}

export async function getAccountData(playFabId: string, sessionTicket: string): Promise<AccountData> {
    const accountInfo = await getAccountInfo(playFabId);
    const playerProfile = await getPlayerProfile(playFabId, sessionTicket);

    const accountData: AccountData = {
        playerID: accountInfo.playerID,
        PrivateInfo: accountInfo.PrivateInfo,
        contactEmail: playerProfile.contactEmail,
    };

    return accountData;
}