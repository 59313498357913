// POST https://titleId.playfabapi.com/Client/AddOrUpdateContactEmail

import { requestPlayfab } from "./playfab-proxy";

export function updateContactEmail(email: string, sessionTicket: string): Promise<boolean> {
    // Set up the request data
    const requestData = {
        endpointUrl: "/Client/AddOrUpdateContactEmail",
        method: "POST",
        customHeaders: {
            'X-Authorization': sessionTicket,
        },
        body: {
            EmailAddress: email,
        },
    };

    // Make the API call
    return requestPlayfab(requestData)
        .then((response) => {
            // Handle the response data
            return true;
        })
        .catch(error => {
            // Handle any errors
            return false;
        });
}