export const colors = {
    blue: {
      1: '#1597C4',
      2: '#133046',
    },
    gray: {
      1: '#FDFBF4',
      2: '#000000',
    },
    orange: {
      1: '#E2863B',
    },
  }