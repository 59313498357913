import { Formik, Field } from "formik";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    VStack,
    Heading,
    Link,
} from "@chakra-ui/react";
import { registerWithEmail } from "../../ApiLib/register";
import { useContext } from "react";
import { LoginContext } from '../../stores/useLogin';
import { useNavigate } from "react-router-dom";
import { updateOwnedCourses } from "../../ApiLib/course-ownership";
import { Course } from "../../CourseLib/course";

function isHttpResponseSuccessful(responseCode: number): boolean {
    return responseCode >= 200 && responseCode < 300;
}

export function RegisterPage() {
    const { login } = useContext(LoginContext);
    const navigate = useNavigate();

    return (
        <Flex bg="gray.1" align="center" justify="center" margin='auto' padding='15px' borderRadius='5px'>
            <VStack spacing={4} w="full" maxW="md" alignContent='center'>
                <Heading size='xl' color='blue.2'>Welcome!</Heading>
                <Heading size='m' color='blue.1'>
                    Create an account below or <Link href="/login" color='orange.1' textDecoration='underline'>
                        login
                    </Link>
                </Heading>
                <Box bg="white" p={6} rounded="md" w={{sm: '100%', md: 400}}>
                    <Formik
                        initialValues={{
                            email: "",
                            password: "",
                        }}
                        onSubmit={async (values) => {
                            const response = await registerWithEmail(values.email, values.password);
                            if (isHttpResponseSuccessful(response.code)) {
                                await updateOwnedCourses(response.PlayFabId, [Course.GETTING_STARTED.toString(), Course.CHEMISTRY.toString()]);
                                login(response);
                                navigate('/dashboard');
                            }
                        }}
                    >
                        {({ handleSubmit, errors, touched }) => (
                            <form onSubmit={handleSubmit}>
                                <VStack spacing={4} align="flex-start">
                                    <FormControl>
                                        <FormLabel htmlFor="email" color='blue.2'>Email Address</FormLabel>
                                        <Field
                                            as={Input}
                                            id="email"
                                            name="email"
                                            type="email"
                                            variant="filled"
                                            focusBorderColor="blue.1"
                                        />
                                    </FormControl>
                                    <FormControl isInvalid={!!errors.password && touched.password}>
                                        <FormLabel htmlFor="password" color='blue.2'>Password</FormLabel>
                                        <Field
                                            as={Input}
                                            id="password"
                                            name="password"
                                            type="password"
                                            variant="filled"
                                            focusBorderColor="blue.1"
                                            validate={(value: string) => {
                                                let error;

                                                if (value.length < 6) {
                                                    error = "Password must contain at least 6 characters";
                                                }

                                                return error;
                                            }}
                                        />
                                        <FormErrorMessage>{errors.password}</FormErrorMessage>
                                    </FormControl>
                                    <Button type="submit" colorScheme="orange" width="full">
                                        Create Account
                                    </Button>
                                </VStack>
                            </form>
                        )}
                    </Formik>
                </Box>
            </VStack>
        </Flex>
    );
}