// Reset password: https://learn.microsoft.com/en-us/rest/api/playfab/admin/account-management/reset-password?view=playfab-rest
// delete account: https://learn.microsoft.com/en-us/rest/api/playfab/admin/account-management/delete-player?view=playfab-rest
import React, { useState, useContext, useEffect, useRef } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Alert,
    AlertIcon,
    AlertDescription,
    Flex,
    Text,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react';
import { LoginContext } from '../../stores/useLogin';
import { getAccountData, defaultAccountData } from '../../ApiLib/account-data';
import { updateContactEmail } from '../../ApiLib/contact-email';
import { deleteAccount } from '../../ApiLib/delete-player';
import { useNavigate } from 'react-router-dom';
import { sendResetPasswordEmail } from '../../ApiLib/reset-password';
import { setOwnedCourses } from '../../ApiLib/course-ownership';

function EmailSection({ showAlert, emailValue, contactEmailValue }: { showAlert: (value: AlertMessageType) => void; emailValue: string; contactEmailValue: string; }) {
    const { sessionTicket } = useContext(LoginContext);
    const [contactEmail, setContactEmail] = useState(contactEmailValue);
    useEffect(() => {
        setContactEmail(contactEmailValue);
    }, [contactEmailValue]);
    const [submitted, setSubmitted] = useState(false);

    const handleChangeEmail = async () => {
        setSubmitted(true);
        showAlert({
            show: true,
            message: 'Changing contact email...',
            status: 'loading',
        });
        // Perform email change logic here
        if (validateEmail(contactEmail) === '' && await updateContactEmail(contactEmail, sessionTicket)) {
            showAlert({
                show: true,
                message: 'Contact Email changed successfully',
                status: 'success',
            });
            //setContactEmail(contactEmail);
            setSubmitted(false);
        } else {
            setSubmitted(true);
            showAlert({
                show: true,
                message: 'Contact Email change failed',
                status: 'error',
            });
        }

    };

    const validateEmail = (value: string) => {
        // Simplified email validation
        if (!value) {
            return 'Email is required';
        }
        return '';
    };

    return (
        <Box>
            <FormControl mb="4">
                <FormLabel>Login Email</FormLabel>
                <Input
                    type="email"
                    value={emailValue}
                    disabled
                />
            </FormControl>

            <FormControl mb="4" isInvalid={submitted && validateEmail(contactEmail) !== ''}>
                <FormLabel>Contact Email</FormLabel>
                <Input
                    type="text"
                    value={contactEmail}
                    onChange={(e) => setContactEmail(e.target.value)}
                />
                <FormErrorMessage>{validateEmail(contactEmail)}</FormErrorMessage>
            </FormControl>

            <Button colorScheme="blue" onClick={handleChangeEmail} mb="4">
                Change Contact Email
            </Button>
        </Box >
    )
}

function PasswordSection({ showAlert, email }: { showAlert: (value: AlertMessageType) => void; email: string; }) {
    const [password, setPassword] = useState('password123');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleChangePassword = async () => {
        // Perform password change logic here
        setSubmitted(true);
        showAlert({
            show: true,
            message: 'Changing password...',
            status: 'loading',
        });
        if (validatePassword(newPassword) === '' && validateConfirmPassword(confirmPassword) === '') {
            if (await sendResetPasswordEmail(email)) {
                showAlert({
                    show: true,
                    message: 'Success: Check your email to finialize the password change',
                    status: 'success',
                });
                setPassword(newPassword);
                setNewPassword('');
                setConfirmPassword('');
                setSubmitted(false);
            }
        }
    };

    const validatePassword = (value: string) => {
        // Simplified password validation
        if (!value) {
            return 'Password is required';
        }
        if (value === password) {
            return 'New password must be different from old password';
        }
        if (value.length < 6 || value.length > 100) {
            return 'Password must be between 6 and 100 characters';
        }
        return '';
    };

    const validateConfirmPassword = (value: string) => {
        // Simplified confirmation password validation
        if (value !== newPassword) {
            return 'Passwords do not match';
        }
        return '';
    };

    return (
        <Box mt="4">
            <FormControl mb="4" isInvalid={submitted && validatePassword(newPassword) !== ''}>
                <FormLabel>New Password</FormLabel>
                <Input
                    type={'password'}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <FormErrorMessage>{validatePassword(newPassword)}</FormErrorMessage>
            </FormControl>

            <FormControl mb="4" isInvalid={submitted && validateConfirmPassword(confirmPassword) !== ''}>
                <FormLabel>Confirm Password</FormLabel>
                <Input
                    type={'password'}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <FormErrorMessage>
                    {validateConfirmPassword(confirmPassword)}
                </FormErrorMessage>
            </FormControl>

            <Button colorScheme="blue" onClick={handleChangePassword} mb="4">
                Change Password
            </Button>
        </Box>
    );
}

function DeleteAccountAlert({ showAlert }: { showAlert: (value: AlertMessageType) => void }) {
    const [isOpen, setIsOpen] = useState(true);
    const onClose = () => setIsOpen(false);
    const cancelRef = useRef(null);
    const { playFabId, logout } = useContext(LoginContext);
    const navigate = useNavigate();

    const handleConfirm = async () => {
        showAlert({
            show: true,
            message: 'Deleting account...',
            status: 'loading',
        });
        onClose();
        const wasDeleted = await deleteAccount(playFabId);
        if (wasDeleted) {
            showAlert({
                show: true,
                message: 'Account deleted successfully',
                status: 'success',
            });
            logout();
            navigate('/register')
        } else {
            showAlert({
                show: true,
                message: 'Account deletion failed',
                status: 'error',
            });
        }
    };

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader>Delete Account</AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure you want to delete your account?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={handleConfirm} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}

function DeleteAccountButton({ showAlert }: { showAlert: (value: AlertMessageType) => void }) {
    const [showConfirm, setShowConfirm] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const deleteAccountText = showConfirm ? 'Are you sure?' : 'Delete Account';

    return (
        <Flex flexDirection='column'>
            <Button colorScheme={showConfirm ? 'gray' : 'red'} onClick={() => setShowConfirm(true)} m='auto' mt='20' isDisabled={showConfirm}>
                {deleteAccountText}
            </Button>
            {showConfirm && (
                <Text>Deleting your account permanently removes your data and purchased courses from our system.</Text>
            )}
            {showConfirm && (
                <Flex flexDirection='row' mt='5'>
                    <Button colorScheme="red" onClick={() => { setShowDeleteModal(true); setShowConfirm(false); }} m='auto'>
                        Confirm
                    </Button>
                    <Button colorScheme="blue" onClick={() => setShowConfirm(false)} m='auto'>
                        Cancel
                    </Button>
                </Flex>
            )}
            {showDeleteModal && (
                <DeleteAccountAlert showAlert={showAlert} />
            )}
        </Flex>

    );
}

function RemoveCoursesButton({ showAlert }: { showAlert: (value: AlertMessageType) => void }) {
    const [showConfirm, setShowConfirm] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const removeCoursesText = showConfirm ? 'Are you sure?' : 'Remove Courses';

    return (
        <Flex flexDirection='column'>
            <Button colorScheme={showConfirm ? 'gray' : 'red'} onClick={() => setShowConfirm(true)} m='auto' mt='20' isDisabled={showConfirm}>
                {removeCoursesText}
            </Button>
            {showConfirm && (
                <Text>This should only be used for testing. Removing all courses is destrutive to your account.</Text>
            )}
            {showConfirm && (
                <Flex flexDirection='row' mt='5'>
                    <Button colorScheme="red" onClick={() => { setShowDeleteModal(true); setShowConfirm(false); }} m='auto'>
                        Confirm
                    </Button>
                    <Button colorScheme="blue" onClick={() => setShowConfirm(false)} m='auto'>
                        Cancel
                    </Button>
                </Flex>
            )}
            {showDeleteModal && (
                <RemoveCoursesAlert showAlert={showAlert} />
            )}
        </Flex>

    );
}

function RemoveCoursesAlert({ showAlert }: { showAlert: (value: AlertMessageType) => void }) {
    const [isOpen, setIsOpen] = useState(true);
    const onClose = () => setIsOpen(false);
    const cancelRef = useRef(null);
    const { playFabId } = useContext(LoginContext);

    const handleConfirm = async () => {
        showAlert({
            show: true,
            message: 'Deleting owned courses from account...',
            status: 'loading',
        });
        onClose();
        const wasDeleted = await setOwnedCourses(playFabId, []);
        if (wasDeleted) {
            showAlert({
                show: true,
                message: 'Courses removed successfully',
                status: 'success',
            });
        } else {
            showAlert({
                show: true,
                message: 'Course removal failed',
                status: 'error',
            });
        }
    };

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader>Remove All Owned Courses</AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure you want to remove your owned courses?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={handleConfirm} ml={3}>
                                Remove
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}

type AlertMessageType = {
    show: boolean,
    message: string,
    status: "info" | "warning" | "success" | "error" | "loading" | undefined
};

export const AccountSettingsPage = () => {
    const { playFabId, sessionTicket } = useContext(LoginContext);
    const [accountData, setAccountData] = useState(defaultAccountData);
    const alertMessage = {
        show: false,
        message: '',
        status: undefined,
    }
    const [showAlertMessage, setshowAlertMessage] = useState<AlertMessageType>(alertMessage);
    const isAdmin = playFabId === '9610EADCE9F679EF';

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getAccountData(playFabId, sessionTicket);
                setAccountData(response);
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, [playFabId, sessionTicket]);

    return (
        <Box w='100%' maxW="400px" margin="auto" mt="4">
            {showAlertMessage.show && (
                <Alert status={showAlertMessage.status} mb="4">
                    <AlertIcon />
                    <AlertDescription>
                        {showAlertMessage.message}
                    </AlertDescription>
                </Alert>
            )}

            <EmailSection showAlert={setshowAlertMessage} emailValue={accountData.PrivateInfo.Email} contactEmailValue={accountData.contactEmail} />
            <PasswordSection showAlert={setshowAlertMessage} email={accountData.PrivateInfo.Email} />

            <DeleteAccountButton showAlert={setshowAlertMessage} />
            {isAdmin && <RemoveCoursesButton showAlert={setshowAlertMessage} />}
        </Box>
    );
};