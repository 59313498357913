import { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    VStack,
    Heading,
    Link,
} from "@chakra-ui/react";
import { loginWithEmail } from "../../ApiLib/login";
import { useContext } from "react";
import { LoginContext } from '../../stores/useLogin';
import { useNavigate } from "react-router-dom";
import { getUserOrganization } from "../../ApiLib/user-organization";
import { removeCharacter } from "../../ApiLib/useful-functions";

function isHttpResponseSuccessful(responseCode: number): boolean {
    return responseCode >= 200 && responseCode < 300;
}

export function LoginPage() {
    const { isLoggedIn, login, School_Role} = useContext(LoginContext);
    const navigate = useNavigate();

    const [successfulLogin, setSuccessfulLogin] = useState(true);

    // Might need to re-enable the below code at some point, but it was messing with my teacher login to teach dashboard functionality
    // Honestly IDK what this code does. But i re-wrote it to do teacher vs public dashboard
    useEffect(() => {
        if (isLoggedIn) {
            const userRole = removeCharacter(School_Role, '"');
            if (userRole === "Teacher") {
                navigate('/Teacher/Dashboard');
            } 
        navigate('/dashboard');
        }
    });

    return (
        <Flex bg="gray.1" align="center" justify="center" margin='auto' padding='15px' borderRadius='5px'>
            <VStack spacing={4} w="full" maxW="md" alignContent='center'>
                <Heading size='xl' color='blue.2'>Welcome back!</Heading>
                <Heading size='m' color='blue.1'>
                    Login below or <Link href="/register" color='orange.1' textDecoration='underline'>
                        create a new account
                    </Link>
                </Heading>
                <Box bg="white" p={6} rounded="md" w={{sm: '100%', md: 400}}>
                    <Formik
                        initialValues={{
                            email: "",
                            password: "",
                            rememberMe: false
                        }}
                        onSubmit={async (values) => {
                            let response = await loginWithEmail(values.email, values.password);
                            let orgResponse = await getUserOrganization(response.PlayFabId);
                            setSuccessfulLogin(isHttpResponseSuccessful(response.code));
                            if (isHttpResponseSuccessful(response.code) && isHttpResponseSuccessful(orgResponse.data.code)) {
                            if (isHttpResponseSuccessful(response.code)) {
                                setSuccessfulLogin(true);
                                login(response, orgResponse, values.rememberMe);
                                // For some WILD reason, role below comes out to "Value" instead of Value if removeChar() isn't used, causing the equality: "Value" === Value, which is false
                                const role = removeCharacter(((orgResponse.data.data.Data.SCHOOL_ROLE || {Value: "Public"}).Value), '"'); 
                                if(role === "Teacher"){ // If the user is a teacher, go to the teacher dashboard insteaed of the normal dashboard
                                navigate('/Teacher/Dashboard');
                                }else{
                                    navigate('/dashboard');
                                }
                            }
                        }
                    }}
                >
                        {({ handleSubmit, errors, touched }) => (
                            <form onSubmit={handleSubmit}>
                                <VStack spacing={4} align="flex-start">
                                    <FormControl>
                                        <FormLabel htmlFor="email" color='blue.2'>Email Address</FormLabel>
                                        <Field
                                            as={Input}
                                            id="email"
                                            name="email"
                                            type="email"
                                            variant="filled"
                                            focusBorderColor="blue.1"
                                        />
                                    </FormControl>
                                    <FormControl isInvalid={!!errors.password && touched.password}>
                                        <FormLabel htmlFor="password" color='blue.2'>Password</FormLabel>
                                        <Field
                                            as={Input}
                                            id="password"
                                            name="password"
                                            type="password"
                                            variant="filled"
                                            focusBorderColor="blue.1"
                                            validate={(value: string) => {
                                                let error;

                                                if (value.length < 6) {
                                                    error = "Password must contain at least 6 characters";
                                                }

                                                return error;
                                            }}
                                        />
                                        <FormErrorMessage>{errors.password}</FormErrorMessage>
                                    </FormControl>
                                    <Link href="/reset-password" color='blue.1' textDecoration='underline' fontSize='sm' mt='-10px' alignSelf="flex-end">
                                        Forgot password?
                                    </Link>
                                    <Field
                                        as={Checkbox}
                                        id="rememberMe"
                                        name="rememberMe"
                                        color="blue.2"
                                        iconColor="white"
                                        colorScheme="orange"
                                    >
                                        Stay logged in? <br></br> (Please do not check the box if this device is shared between multiple people)
                                    </Field>
                                    <Button type="submit" colorScheme="orange" width="full">
                                        Login
                                    </Button>
                                    {!successfulLogin && <Box bg='red.500' textColor='white' padding='4px 8px' borderRadius='4px'>Incorrect email or password</Box>}
                                </VStack>
                            </form>
                        )}
                    </Formik>
                </Box>
            </VStack>
        </Flex>
    );
}