import React from 'react';
import { Link } from '@chakra-ui/react';

interface DownloadButtonProps {
  fileLocation: string;
  fileName: string;
  buttonText: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ fileLocation, fileName, buttonText }) => {
  return (
    <Link
      href={fileLocation}
      download={fileName}
      p={4}
      bg="teal.500"
      color="white"
      borderRadius="md"
      _hover={{ bg: 'teal.600' }}
      display="inline-block"
      textAlign="center"
      fontWeight="bold"
      textDecoration="none"
    >
      {buttonText}
    </Link>
  );
};

export default DownloadButton;
