import React from "react";
import { Link, Image, Flex, Heading } from "@chakra-ui/react";
import vLabLogo from "../images/updated_color_v1.png";
import { BaseFooter } from "./Footer";

function BaseHeader({headerTitle}: {headerTitle?: string}) {
    return (
        <Flex
            bg="white"
            w='100%'
            h='60px'
            alignItems="center"
            justifyContent='space-between'
        >
            <Image src={vLabLogo} alt="vLab Logo" w='100px' margin='0 20px' />
            {headerTitle && <Heading size="lg" padding='10px'>{headerTitle}</Heading>}
            <Link href="https://www.v-lab-education.com/" color='blue.1' margin='0 20px'>Back to main site</Link>

        </Flex>
    );
}

export function PublicPageWrapper(props: any) {
    return (
        <Flex flexDirection='column' minH='100vh' bg='blue.1'>
            <BaseHeader headerTitle={props.headerTitle} />
            <Flex flex='1'>
                {props.children}
            </Flex>
            <BaseFooter />
        </Flex>
    );
}