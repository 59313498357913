import React, { createContext, useState } from 'react';
import { getCookieJSON, setCookieJSON } from '../browserStorage/first_party_cookies_lib';

const CART_ITEMS_COOKIE = 'cart-items-cookie';

export interface CartItemType {
  courseid: string;
}

interface CartItemsContextType {
  cartItems: CartItemType[];
  addItem: (courseid: string) => void;
  removeItem: (courseid: string) => void;
  clearCart: () => void;
}

const CartItemContext = createContext<CartItemsContextType>({
  cartItems: [],
  addItem: (courseid: string) => { },
  removeItem: (courseid: string) => { },
  clearCart: () => { }
})

interface CartItemsProviderProps {
  children: React.ReactNode;
}

const CartItemsProvider: React.FC<CartItemsProviderProps> = ({ children }) => {
  const useCartItems = () => {
    const cartItemsCookie = getCookieJSON(CART_ITEMS_COOKIE) ?? [];
    const [cartItems, setCartItems] = useState(cartItemsCookie);

    const addItem = (courseid: string) => {
      const newCartItems = [...cartItems, { courseid }];
      setCartItems(newCartItems);
      setCookieJSON({ name: CART_ITEMS_COOKIE, value: newCartItems, days: null });
    };

    const removeItem = (courseid: string) => {
      const newCartItems = cartItems.filter((item: CartItemType) => item.courseid !== courseid);
      setCartItems(newCartItems);
      setCookieJSON({ name: CART_ITEMS_COOKIE, value: newCartItems, days: null });
    };

    const clearCart = () => {
      setCartItems([]);
      setCookieJSON({ name: CART_ITEMS_COOKIE, value: [], days: null });
    };

    return { cartItems, addItem, removeItem, clearCart };
  };

  const cartItemContext = useCartItems();

  return (
    <CartItemContext.Provider value={cartItemContext}>
      {children}
    </CartItemContext.Provider>
  );
};

export { CartItemsProvider, CartItemContext };
