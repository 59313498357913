// import React, { useState, useEffect, useContext } from "react";
import {
    Flex,
    VStack,
    // Box,
    // Heading,
    // Text,
    // Image,
    // List,
    // ListItem,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Link,
} from '@chakra-ui/react';
// import { ArrowRightIcon } from "@chakra-ui/icons";
// import { useParams } from "react-router-dom";
// import { LoginContext } from "../../../stores/useLogin";
// import { getOwnedCourses } from "../../../ApiLib/course-ownership";
// import { LoadingBox } from "../../Components/LoadingBox";
// import { FourOhThree } from "../../Components/403";
// import { FourOhFour } from "../../Components/404";
// import type { CourseMeta, CourseStore, Lab } from "../../../CourseLib/course";
// import { getCourseMetaByIds } from "../../../CourseLib/course_meta";
// import { getStoreInfoByIds } from "../../../CourseLib/course_store";
import { useNavigate } from "react-router-dom";
// import { getUserOrganization } from "../../../ApiLib/user-organization";
import { CheckIfUserIsTeacherOr404 } from "../../../ApiLib/useful-functions";

export function TeacherDashboard() {
    const navigate = useNavigate();

    // const { Organization, School_Role } = useContext(LoginContext);   

    //BLOCKER TO DISALLOW NON TEACHERS FROM ACCESSING
    CheckIfUserIsTeacherOr404();
    
    function ComingSoon() {
      const { isOpen, onOpen, onClose } = useDisclosure();
      return (
        <>
          <Button onClick={onOpen} bg='blue.500' textColor={"white"}>Student Management</Button>
    
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader >This feature is coming soon!</ModalHeader>
              <ModalCloseButton />
              <ModalBody text-align="center">
                  <p>For now, please contact <Link color="blue.500" target="_blank" rel="noreferrer" href="mailto:SCassellius@v-lab-education.com">SCassellius@v-lab-education.com</Link> for any changes to your class roster.</p>
              </ModalBody>
    
              <ModalFooter>
                <Button bg="blue.500" textColor="white" mr={3} onClick={onClose}>
                  Close
                </Button>
               
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      );
    }

    return (
        <Flex bg="#3e7800" align="center" justify="center" w='auto' h='auto' margin='auto' padding='30px' borderRadius='5px'>
            <VStack spacing={4} w="full" maxW="md" alignContent='center'>
                {/* <Button colorScheme='blue' w='100%'>Student Management</Button>  Add / Remove students */}
                {ComingSoon()}
                <Button bg='blue.500' textColor={"white"} w='100%' onClick={() => navigate('/Teacher/Answers')}>Student Answers</Button> {/* Student Data Pad Answers */}
                <Button bg='blue.500' textColor={"white"} w='100%' onClick={() => navigate('/Teacher/Resources')}>Resource Materials</Button> {/* I had a 4th but i dont remember rn */}
            </VStack>
        </Flex>
    );
}