import { requestPlayfab } from "./playfab-proxy";

export type DisplayNameResponse = { // This is the required data structure I discovered via Postman
    data:{
        code: number;
        data:{
            PlayerProfile: {
            PublisherId: string;
            TitleId: string;
            PlayerId: string;
            DisplayName: string;
            }
            status: string;
        }
    }    
}

export async function getPlayerProfile(playFabId: string): Promise<DisplayNameResponse> {
    // Request User's data from the PlayFab server
    const requestData = {
        endpointUrl: "/Admin/GetPlayerProfile",
        method: "POST",
        body: {
            PlayFabId: playFabId,
        },
    };

    return requestPlayfab(requestData);

}