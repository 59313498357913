import {
    Flex,
    Box,
} from '@chakra-ui/react';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Text,
  } from '@chakra-ui/react'
import DownloadButton from "../../Components/downloadButton";
import { CheckIfUserIsTeacherOr404 } from '../../../ApiLib/useful-functions';


export function TeacherResources() {
    //BLOCKER TO DISALLOW NON TEACHERS FROM ACCESSING
    CheckIfUserIsTeacherOr404();
 
    return (
        <Flex align="center" justify="center" w='auto' h='auto' margin='auto' padding='30px' borderRadius='5px'>
        <Flex padding='80px'>
        <Accordion defaultIndex={[]} allowMultiple background={"#3e7800"}>
            <Text align="center" color="white" fontWeight="bold" sx={{
        textDecoration: 'underline'}}>Safety Data Sheets</Text>
    
        <AccordionItem>
            <h2>
            <AccordionButton>
                <Box as='span' flex='1' textAlign='left' color="white" fontWeight="bold">
                Lab 4: Hydrate Experiment
                </Box>
                <AccordionIcon />
            </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <DownloadButton
                    fileLocation="/sds-sheets/Hydrate-Experiment-SDS.zip" // Path to the file in the public folder
                    fileName="Hydrate-Experiment-SDS.zip"
                    buttonText="Download File"
                />
            </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
            <h2>
            <AccordionButton>
                <Box as='span' flex='1' textAlign='left' color="white" fontWeight="bold">
                Lab 5: Precipitation Experiment
                </Box>
                <AccordionIcon />
            </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <DownloadButton
                    fileLocation="/sds-sheets/Precipitation-Experiment-SDS.zip" // Path to the file in the public folder
                    fileName="Precipitation-Experiment-SDS.zip"
                    buttonText="Download File"
                />
            </AccordionPanel>
        </AccordionItem>
        </Accordion>
        </Flex>



        <Flex padding='80px'>
        <Accordion defaultIndex={[]} allowMultiple background={"#3e7800"}>
        <Text align="center" color="white" fontWeight="bold" sx={{
        textDecoration: 'underline'}}>Prelab Sheets</Text>

        <AccordionItem>
            <h2>
            <AccordionButton>
                <Box as='span' flex='1' textAlign='left' color="white" fontWeight="bold">
                Lab 3: Element Flame Test Experiment
                </Box>
                <AccordionIcon />
            </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <DownloadButton 
                    fileLocation="/worksheets/chemistry/Element_Flame_Test.pdf" // Path to the file in the public folder
                    fileName="Element_Flame_Test.pdf"
                    buttonText="Download File"
                />
            </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
            <h2>
            <AccordionButton>
                <Box as='span' flex='1' textAlign='left' color="white" fontWeight="bold">
                Lab 4: Hydrate Experiment
                </Box>
                <AccordionIcon />
            </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <DownloadButton
                    fileLocation="/worksheets/chemistry/Hydrate.pdf" // Path to the file in the public folder
                    fileName="Hydrate.pdf"
                    buttonText="Download File"
                />
            </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
            <h2>
            <AccordionButton>
                <Box as='span' flex='1' textAlign='left' color="white" fontWeight="bold">
                Lab 5: Precipitation Experiment
                </Box>
                <AccordionIcon />
            </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <DownloadButton
                    fileLocation="/worksheets/chemistry/Precipitation.pdf" // Path to the file in the public folder
                    fileName="Precipitation.pdf"
                    buttonText="Download File"
                />
            </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
            <h2>
            <AccordionButton>
                <Box as='span' flex='1' textAlign='left' color="white" fontWeight="bold">
                Lab 6: Heat Capacity and Calorimetry Experiment
                </Box>
                <AccordionIcon />
            </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <DownloadButton
                    fileLocation="/worksheets/chemistry/Heat_Capacity.pdf" // Path to the file in the public folder
                    fileName="Heat_Capacity.pdf"
                    buttonText="Download File"
                />
            </AccordionPanel>
        </AccordionItem>

        </Accordion>
        </Flex>
        </Flex>
    );
}