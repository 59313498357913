// import { delay } from "framer-motion";
import { requestPlayfab } from "./playfab-proxy";

export type LoginResponse = {
    EntityToken: string;
    TokenExpiration: string;
    PlayFabId: string;
    code: number;
    SessionTicket: string;
};


export function loginWithEmail(email: string, password: string): Promise<LoginResponse> {
    // Set up the request data
    const requestData = {
        endpointUrl: "/Client/LoginWithEmailAddress",
        method: "POST",
        body: {
            TitleId: true,
            Email: email,
            Password: password
        },
    };


    return requestPlayfab(requestData)
        .then((response) => {
            // Handle the response data
            const data = response.data;
            return { EntityToken: data.data.EntityToken.EntityToken, TokenExpiration: data.data.EntityToken.TokenExpiration, code: data.code, PlayFabId: data.data.PlayFabId, SessionTicket: data.data.SessionTicket};
        })
        .catch(error => {
            // Handle any errors
            const code = error.response.status;
            return { code: code, EntityToken: '', TokenExpiration: '', PlayFabId: '', SessionTicket: ''};
        });
}