import { requestPlayfab } from "./playfab-proxy";

const OWNED_COURSES_KEY = "OWNED_COURSES_KEY";
const API_ERROR = '-1';

// POST https://titleId.playfabapi.com/Admin/UpdateUserData
export async function setOwnedCourses(playFabId: string, courseids: string[]): Promise<boolean> {
    // Set up the request data
    const requestData = {
        endpointUrl: "/Admin/UpdateUserData",
        method: "POST",
        body: {
            playFabId: playFabId,
            Data: {
                OWNED_COURSES_KEY: JSON.stringify(courseids),
            },
        },
    };

    // Make the API call
    return requestPlayfab(requestData)
        .then((response) => {
            // Handle the response data
            return true;
        })
        .catch(error => {
            // Handle any errors
            return false;
        });
}

export async function updateOwnedCourses(playFabId: string, courseids: string[]): Promise<boolean> {
    const currentlyOwnedCourses = await getOwnedCourses(playFabId);

    if (currentlyOwnedCourses[0] === API_ERROR) {
        return false;
    }

    return setOwnedCourses(playFabId, Array.from(new Set(currentlyOwnedCourses.concat(courseids))));
}

export async function getOwnedCourses(playFabId: string): Promise<string[]> {
    // Set up the request data
    const requestData = {
        endpointUrl: "/Admin/GetUserData",
        method: "POST",
        body: {
            PlayFabId: playFabId,
            Keys: [OWNED_COURSES_KEY],
        },
    };

    // Make the API call
    const response = await requestPlayfab(requestData)
        .then((response) => {
            return response;
        }).catch(error => {
            return error;
        });

    if (response.status !== 200) {
        return [API_ERROR];
    }

    if (response.data.data.Data !== undefined && response.data.data.Data[OWNED_COURSES_KEY] !== undefined && response.data.data.Data[OWNED_COURSES_KEY].Value !== undefined) {
        return JSON.parse(response.data.data.Data[OWNED_COURSES_KEY].Value);
    }

    return [];
}