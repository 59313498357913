// POST https://titleId.playfabapi.com/Admin/DeletePlayer

import { requestPlayfab } from "./playfab-proxy";

export function deleteAccount(playFabId: string): Promise<boolean> {
    // Set up the request data
    const requestData = {
        endpointUrl: "/Admin/DeleteMasterPlayerAccount",
        method: "POST",
        body: {
            PlayFabId: playFabId,
        },
    };

    // Make the API call
    return requestPlayfab(requestData)
        .then((response) => {
            // Handle the response data
            return true;
        })
        .catch(error => {
            // Handle any errors
            console.error(error);
            return false;
        });
}