function setCookie({ name, value, days = null }: { name: string, value: string, days: number | null }): void {
    let cookieString = `${name}=${value}; path=/`;
    if (days !== null) {
        const expires = new Date();
        expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
        cookieString += `; expires=${expires.toUTCString()}`;
    }
    document.cookie = cookieString;
}

function getCookie(name: string): string | null {
    const keyValue = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
}

export function setCookieJSON({ name, value, days = null }: { name: string, value: any, days: number | null }): void {
    setCookie({ name, value: JSON.stringify(value), days });
}

export function getCookieJSON(name: string): any | null {
    const value = getCookie(name);
    return value ? JSON.parse(value) : null;
}