import React from "react";
import { Heading, Link, VStack } from "@chakra-ui/react";

export function BaseFooter() {
    return (
        <VStack
            bg="blue.2"
            w="100%"
            h={20}
            p='10px'
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex="0"
        >
            <Link href="https://www.v-lab-education.com/" color='white'>Back to main site</Link>
            <Heading size="sm" color="white">
            ⓒ Copyright VLab Education 2024
            </Heading>

        </VStack>
    );
}