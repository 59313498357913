import React, { useState } from "react";
import { Formik, Field } from "formik";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Heading,
    Link,
    Text,
} from "@chakra-ui/react";
import { sendResetPasswordEmail } from "../../ApiLib/reset-password";

function LinkSent({email}: {email: string}) {
    return (
        <VStack spacing={4} align="flex-start">
            <Heading size='xl' color='blue.2'>Email sent!</Heading>
            <Heading size='sm' color='blue.1'>
                We've sent a link to {email} so you can reset your password.
            </Heading>
            <Link href="/login" color='blue.1'>
                Remember it now? <Text color='orange.1' textDecoration='underline'>Login</Text>
            </Link>
        </VStack>
    );
}

function ErrorText({email}: {email: string}) {
    return (
        <VStack spacing={4} align="flex-start">
            <Heading size='sm' color='blue.1'>
                We couldn't find an account associated with {email}.
            </Heading>
        </VStack>
    );
}

export function ResetPasswordPage() {
    let [emailInput, setEmailInput] = useState("");
    let [sentEmail, setSentEmail] = useState(false);

    return (
        <Flex bg="gray.1" align="center" justify="center" margin='auto' padding='15px' borderRadius='5px'>
            <VStack spacing={4} w="full" maxW="md" alignContent='center' justifyContent='center'>
                <Heading size='xl' color='blue.2'>Reset Password</Heading>
                <Heading size='m' color='blue.1'>
                    We'll send a link to your email so you can reset your password.
                </Heading>
                <Link href="/login" color='blue.1'>
                    Remember it now? <Text color='orange.1' textDecoration='underline'>Login</Text>
                </Link>
                <Box bg="white" p={6} rounded="md" w={{sm: '100%', md: 400}}>
                    <Formik
                        initialValues={{
                            email: "",
                            password: "",
                            rememberMe: false
                        }}
                        onSubmit={async (values) => {
                            const sentEmail = await sendResetPasswordEmail(values.email);
                            setEmailInput(values.email);
                            setSentEmail(sentEmail);
                        }}
                    >
                        {({ handleSubmit, errors, touched }) => (
                            <form onSubmit={handleSubmit}>
                                <VStack spacing={4} align="flex-start">
                                    <FormControl>
                                        <FormLabel htmlFor="email" color='blue.2'>Email Address</FormLabel>
                                        <Field
                                            as={Input}
                                            id="email"
                                            name="email"
                                            type="email"
                                            variant="filled"
                                            focusBorderColor="blue.1"
                                        />
                                    </FormControl>
                                    <Button type="submit" colorScheme="orange" width="full">
                                        Send Reset Link
                                    </Button>
                                    {sentEmail && (<LinkSent email={emailInput} />)}
                                    { emailInput && !sentEmail && (<ErrorText email={emailInput} />)}
                                </VStack>
                            </form>
                        )}
                    </Formik>
                </Box>
            </VStack>
        </Flex>
    );
}