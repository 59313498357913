export enum Course {
    GETTING_STARTED = 1,
    CHEMISTRY = 2,
}

export type CourseMeta = {
    courseid: string,
    name: string,
    description: string,
    image?: string,
    color: string,
    price: number,
};

export type CourseStore = {
    courseid: string,
    summary: string,
    labs: Lab[],
};

export type Lab = {
    name: string,
    description: string,
    learningObjectives: string[] | null,
    worksheetPath: string,
    specialInstructions?: string,
    hasSDS?: boolean,
    screenshots?: string[] | null,
    caption?: string,
    dataTable?: string,
    video?: string,
};